﻿.subcatalog {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  &+.content {
    padding-bottom: 0;
  }
}

// Tabs
input.tabControl {
  display: none;

  &+label {
    display: inline-block;
    margin: lineHeight()/2 .5rem lineHeight()/2 0;
    padding-right: .5rem;
    font-weight: bold;
    text-align: center;
    color: $textLight;
    border-right: 1px solid $line;

    &:hover {
      color: $textTitle;
      cursor: pointer;
    }
  }

  &:first-child+label {
    padding-left: .5rem;
    border-left: 1px solid $line;
  }

  &:checked+label {
    color: $textTitle;
  }
}

#Description:checked ~ #tabDescription,
#List:checked ~ #tabList,
#Schema:checked ~ #tabSchema,
#Advantages:checked ~ #tabAdvantages,
#Price:checked ~ #tabPrice {
  display: block;
  transition: all .5s;
}
.tabContent {
  width: 100%;
  display: none;
  padding-top: lineHeight()*2;
  padding-bottom: lineHeight();
  border-top: 1px solid $line;
  transition: all .5s;

  .gallery {
    border-bottom: 1px solid $line;
  }
}