﻿// Main Styles
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &:before, &:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
:focus {
  outline: none;
}

html, body {
  height: 100%;
}
body {
  @include typeSetting(0);

  font-family: $fontBase;
  background: $bg;
  color: $text;
}
img {
  max-width: 100%;
  height: auto;
}
.container, .header, .footer, .nav, .breadcrumbs, .info {
  @extend %clearfix;

  width: 100%;
  margin: 0 auto;
}

.wrapper, .page, .indexPortfolio {
  @extend %clearfix;

  width: 100% - gutter()*2;
  margin: 0 gutter();

  @include breakpoint($xl) {
    max-width: $xl;
    margin: 0 auto;
  }
}

.main {
  width: 100%;
}

a.svg {
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.bgLight, .breadcrumbs, .infoLink {
  background-color: $bgLight;
}
.bgDark, .infoLinkDark {
  background-color: $bgDark;
}
.bgDarken {
  background-color: $bgDarken;
}
/*
!*baseline grid*!
.main {
position: relative;
  &:before {
    content: '';
    //background: susy-svg-grid() no-repeat scroll;
    @include baseline-grid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
}
*/

// Header styles
.header {
  @extend %clearfix;
  @include container();

  .social {
    display: none;

    @include breakpoint($md) {
    display: block;
    }
  }

  .topLine {
    position: fixed;
    z-index: 50;
    opacity: .9;

    &+.container {
      padding-top: 50px;
    }

    @include breakpoint($md) {
      position: relative;
      opacity: 1;

      &+.container {
        padding-top: 0;
      }
    }
  }

  //@include baseline-grid;
}

.logo, .slogan {
  min-height: lineHeight()*3;
  line-height: lineHeight()*3;

  @include breakpoint($md) {
    min-height: lineHeight()*4;
    line-height: lineHeight()*4;
  }
}

.logo {
  width: 150px;
  height: 60px;
  margin: lineHeight()/2 auto 0;
  float: none;

  object {
    width: 150px;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  @include breakpoint($md) {
    width: span(3);
    float: left;
    margin: 0;
  }
}

.slogan{
  width: 100%;
  float: none;
  text-align: center;

  p {
    margin: auto;
    color: $textSlogan;
    text-align: center;
    font-family: $fontDecor;
    font-size: typeScale(0);
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 0;

    @include breakpoint($xs) {
      font-size: typeScale(1);
    }
    @include breakpoint($lg) {
      font-size: typeScale(2);
    }
  }

  @include breakpoint($md) {
    width: span(6);
    float: left;
  }
}

.telTop {
  width: 100%;
  float: none;
  text-align: center;
  min-height: lineHeight()*2;
  line-height: lineHeight();

  p {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 0;
    padding-top: lineHeight()/2;
  }

  a, a:visited {
    color: $bg;
    font-weight: 300;
    display: inline-block;
    width: auto;
    text-align: center;

    &:not(:first-of-type) {
      padding-left: 12px;
    }

    &:first-of-type:before {
      content: '';
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $bgDark;
      background-image: url("../img/icon_phone.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70%;
      margin-bottom: -10px;
      margin-right: 10px;
    }
  }

  a:hover, a:focus {
    color: $textSlogan;
  }

  @include breakpoint($md) {
    width: span(3);
    float: right;
    text-align: right;
    min-height: lineHeight()*4;
    line-height: lineHeight()*4;

    p {
      padding-top: 0;
    }

    a, a:visited {
      text-align: right;
      width: 100%;
    }
  }
}

// Footer styles
.footer {
  @extend %clearfix;
  padding-top: lineHeight();

  p, a, a:hover, a:focus, a:visited {
    font-size: typeScale(-1);
    color: $textSlogan;
    display: inline-block;
  }
  a:hover, a:focus {
    color: $active;
  }

  h2 {
    color: $bg;
  }

  .wrapper {
    padding: lineHeight()/2 0;
  }

  .signature-wrapper {
    padding-bottom: lineHeight()/2;

    @include breakpoint($md) {
      padding-bottom: 0;
    }
  }

  .social {
    text-align: center;
    width: 100%;

    ul {
      display: inline-block;
      padding: 0;
      overflow: auto;
    }

    @include breakpoint($md) {
      text-align: left;
      width: span(5);
    }
  }
}

.footerContacts {
  width: 280px;
  margin: 0 auto;
  float: none;

  @include breakpoint($md) {
    float: left;
    width: span(4);
    padding-top: lineHeight()/2;
    margin: 0;
  }
}
.footerPartners {
  width: 280px;
  text-align: center;
  margin: lineHeight()/2 auto 0;
  float: none;

  a, a:visited {
    opacity: .7;
  }
  a:hover, a:focus {
    opacity: 1;
  }

  @include breakpoint($md) {
    text-align: left;
    float: right;
    width: span(2);
    padding-top: lineHeight()/2;
    margin: 0;
  }
}
.footerCatalog, .footerInfo {
  display: none;

  @include breakpoint($md) {
    display: block;
    float: left;
    width: span(3);
    padding-top: lineHeight()/2;
  }
}

.footerItem {
  position: relative;
  padding-top: 1px;
  margin-bottom: lineHeight();

  p {
    margin-left: 30px;
    padding-bottom: 0;
  }

  a, a:hover, a:focus, a:visited {
    width: 100%;
  }
}
.signature {
  width: 100%;
  float: none;
  text-align: center;

  @include breakpoint($md) {
    width: span(7);
    float: right;
    text-align: right;
  }
}
.copy, .linecore {
  @include typeSetting(-2);

  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: rgba($textSlogan, .7);

  @include breakpoint($md) {
    width: span(7);
    text-align: right;
    margin: 0;
    display: inline;
  }
}
.linecore {
  span {
    font-weight: bold;
  }

  a {
    color: $textSlogan;
    text-transform: uppercase;

    &:hover, &:focus {
      color: $colorSound;

      span {
        color: $active;
      }
    }
  }
}

//Section styles
.clientsLogo, .clientsLogo2 {
  overflow: hidden;
  margin: 2*lineHeight() auto;

  div {
    display: flex;
  }
}
.clientsLogo2 {
  display: none;

  @include breakpoint($lg) {
    display: block;
  }
}
.clientLogo {
  align-items: center;
  justify-content: center;
  padding: lineHeight()/2;

  img {
    max-height: 70px;
    filter: grayscale(100%);
    opacity: .7;
  }
}
.blog {
  max-width: 800px;
  margin: 0 auto lineHeight();

  img {
    margin: lineHeight()/2 auto;
    max-width: none;
    width: 100%;
  }
}
.blogImg {
  margin-bottom: lineHeight();
}
.content {
  padding-bottom: lineHeight();

  a, a:hover, a:focus, a:visited {
    color: $colorArtists;
    text-decoration: none;
  }
  a:hover, a:focus {
    text-decoration: underline;
    color: $active;
  }
}
.title {
  color: $bgDark;
  text-transform: none;
  margin-top: lineHeight();

  &Estrada {
    color: $active;
    text-transform: none;
    margin: lineHeight()*2 auto;
    padding-left: 0;
    border-left: none;
    text-align: center;
    font-size: 1.95rem;
    line-height: lineHeight(3);

    @include breakpoint($md) {
      font-size: 2.44rem;
      line-height: 2*1.563rem;
    }
  }
}
.titleLink {
  margin-top: lineHeight();
  color: $colorArtists;
  position: relative;
  text-transform: none;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 12px;
    bottom: 4px;
    border: 6px solid;
    color: transparent;
    border-left-color: $active;
  }

  &:hover {
    color: $active
  }
}
.infoLink, .infoLinkDark {
  width: 100%;
  padding: lineHeight() 0;

  .wrapper {
    display: flex;
  }

  a, a:hover, a:focus, a:visited {
    display: block;
    width: 25%;
    font-size: typeScale(-1);
    color: $text;
    text-align: center;
    text-decoration: none;
    border-bottom: none;
  }
  a:hover, a:focus {
    color: $active;
    text-decoration: none;
  }

  @include breakpoint(max-width ($md - 1)) {
    .wrapper {
      display: block;
    }
    a, a:hover, a:focus, a:visited {
      width: 50%;
      margin: auto;

      &:not(:last-of-type) {
        padding-bottom: lineHeight()/2;
        border-bottom: 1px solid rgba($text, .15);
      }
      &:not(:first-of-type) {
        padding-top: lineHeight()/2;
      }
    }
  }
}
.infoLinkDark {
  a, a:visited {
    color: $textSlogan;
  }
  a:hover, a:focus {
    color: $active;
  }
}

@include breakpoint(max-width ($md - 1)) {
  .infoLinkDark {
    a, a:hover, a:focus, a:visited {
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba($textSlogan, .3);
      }
    }
  }
}

.popular {
  max-width: 480px;
  width: 100%;
  margin: 0 auto lineHeight();
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a, a:hover, a:focus, a:visited {
    text-decoration: none;
  }

  @include  breakpoint($lg) {
    max-width: inherit;
  }
}
.popularItem {
  width: span(12);
  margin-bottom: lineHeight();
  transition: all 0.5s ease;

  &:hover, &:focus {
    .popularImg:before {
      animation: shine .75s;
    }

    .popularTitle {
      color: $active;
      transition: all .5s;
    }
  }

  @include  breakpoint($lg) {
    width: span(4);
  }
}
.popularImg {
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    transform: skewX(-25deg);
  }
}

.popularTitle {
  margin-top: lineHeight()/2;
  color: $textTitle;
  font-weight: bold;
  text-decoration: none;
  transition: all .5s;
}
.tags, .tagsMain {
  @include typeSetting(-1);

  color: $textLight;
  padding: 2*lineHeight() 0;
}
.tagsMain {
  border-top: 1px solid $line;
}

.error  {
  font-size: .75rem;
  display: block;
  position: absolute;
  width: 100%;
  bottom: 5px;
}

// Pop up
.popupWrap {
  position: relative;
  max-width: 600px;
  width: 100%;
  background-color: $bg;
  padding: lineHeight()*2 lineHeight() 0;
  margin: auto;
  transition: all .7s ease-in-out;
}
.popupBack {
  display: none;
  overflow: auto;
  align-items: center;
  position: fixed;
  z-index: 200;
  padding: lineHeight()/2;
  background-color: rgba($bgDarken, .8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.close {
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: white;
  display: block;
  transform-origin: center;


  &:after,
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: $textDark;
    transform: rotate(-45deg);
    top: 12px;
  }

  &:before {
    transform: rotate(45deg);
    top: 12px;
  }
}
