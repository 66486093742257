﻿// Gallery styles

.gallery {
  width: 100%;
  margin-top: lineHeight()*1.5;
  display: flex;
  flex-wrap: wrap;

  a, a:hover, a:focus, a:visited {
    text-decoration: none;
  }
}
.galleryItemTile {
  width: span(12);

  margin-left: 0;
  margin-bottom: lineHeight();

  .itemImg {
    margin-bottom: lineHeight()/2;
  }

  &:hover, &:focus {
    .itemTitle, .itemUsedTitle {
      color: $active;
      transition: all .5s;
    }
    .itemImg img {
      max-width: 110%;
      left: -5%;
      transition: all .5s;
    }
    .itemUsedImg {
      background-color: $bg;

      img {
        opacity: 0;
        transition: all .5s;
      }
    }
  }

  @include breakpoint($xs) {
    width: span(6);
    margin-left: gutter();

    &:nth-child(2n+1) {
      margin-left: 0;
    }
  }
  @include breakpoint($lg) {
    width: span(4);

    &:nth-child(2n+1) {
      margin-left: gutter();
    }
    &:nth-child(3n+1) {
      margin-left: 0;
    }
  }
}
.galleryItemBar {
  max-width: 400px;
  width: 100%;
  display: block;
  position: relative;
  margin: lineHeight() auto;
  text-align: center;
  transition: all .5s;

  &:not(:last-of-type) {
    border-bottom: 1px solid $line;
    padding-bottom: lineHeight();
  }

  .itemImg {
    width: 100%;
    height: 15rem;
    background-color: transparent;
    float: none;
    transition: all .5s;
  }
  .itemText {
    margin-left: 0;
    cursor: default;
    opacity: 1;
    transition: all .5s;

    h3 {
      margin-top: lineHeight()/2;
      margin-bottom: lineHeight()/2;
    }
  }
  .moreItem {
    height: auto;
    color:  $textLight;
    position: relative;
    margin: auto;
    transition: all .5s;

    &:visited {
      color:  $textLight;
    }
  }

  &:hover, &:focus {
    .itemImg img {
      max-width: 110%;
      left: -5%;
      transition: .5s;
    }

    .itemImg img.noHover {
      max-width: 100%;
      left: 0;
    }
    .moreItem {
      animation-name: buzz-out;
      animation-duration: .75s;
      animation-timing-function: linear;
      animation-iteration-count: 1;

      text-decoration: none;
      color: $bg !important;

      &:before {
        transform: scale(1);
      }
    }
  }

    @include breakpoint($md) {
    max-width: 100%;
    margin-top: 0;

    .itemImg {
      width: 210px;
      height: 10rem;
      float: left;
    }
    .itemText {
      margin-left: 250px;
      opacity: .5;
      cursor: default;

      h3 {
        margin-top: 0;
      }
    }

    .moreItem {
      position: relative;
      float: right;
    }

    &:hover {
      opacity: 1;
      transition: all .5s;

      .itemText {
        opacity: 1;
      }
    }
  }
}
.moreItem {
  margin-bottom: 0;
  font-size: typeScale(-1);

  @include breakpoint($md) {
    font-size: typeScale(0);
  }
}
.moreItem:hover, .moreItem:hover:focus {
  border-bottom-color: $line;
}
.itemText {

}
.itemImg {
  display: block;
  height: 10rem;
  width: 100%;
  overflow: hidden;
  background-color: $bgLight;
  position: relative;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: contain;
    margin: auto;
    transition: all .5s;
  }
}
.itemTitle, .itemUsedTitle {
  color: $textTitle;
  text-decoration: none;
  transition: all .5s;
}

// Photo gallery
.galleryPhoto {
  justify-content: center;
}
.galleryPhotoItem {
  display: block;
  max-width: 250px;
  width: 250px;
  height: 12.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: lineHeight();
  position: relative;
  overflow: hidden;

  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow, transform;

  img {
    max-width: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: all .5s;
  }

  &:hover {
    box-shadow: 0 10px 10px -10px rgba($bgDarken, 0.8);
    transform: scale(1.05);
  }

  @include breakpoint($xs) {
    width: calc(50% - 1.56rem);
    margin-left: .78rem;
    margin-right: .78rem;
  }
  @include breakpoint($sm) {
    width: calc(100% / 3 - 1.56rem);
  }
  @include breakpoint($md) {
    width: calc(50% - 1.56rem);
  }
  @include breakpoint($lg) {
    width: calc(100% / 3 - 1.56rem);
  }
  @include breakpoint($xl) {
    width: calc(25% - 1.56rem);
  }
}

// Service page
.service, servicePage {
  .popular {
    margin: 0 auto;
  }
}

.servicePage .popular {
  margin-top: lineHeight();
}

// Used equipment & services
.itemUsedImg {
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 4*lineHeight();
  width: 4*lineHeight();
  overflow: hidden;
  position: relative;
  border: 1px solid $line;
  float: left;

  img {
    opacity: .2;
  }
}
.itemUsedTitle {
  width: calc(100% - 110px);
  height: 3.8rem;
  overflow: hidden;
  float: right;

  @include typeSetting(-1);
  line-height: 1.25rem;
}
.itemUsedPrice {
  @include typeSetting(-1);

  line-height: 1.25rem;
  color: $active;
  position: absolute;
  left: 110px;
  bottom: 0;
}

//
.carousel-similar {
  @extend %clearfix;

  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: lineHeight()*2;

  .slick-prev,
  .slick-next {
    cursor: pointer;
    position: absolute;
    display: block;
    top: auto;
    bottom: -1*lineHeight();
    height: 40px;
    width: 40px;
    background: white;
    color: transparent;
    padding: 0;
    border: 1px solid $line;
    border-radius: 0;
    line-height: 0;
    font-size: 0;
    outline: none;
    z-index: 19;

    transition: all .5s;

    &:before {
      content: '';
      border-top: 1px solid $text;
      border-right: 1px solid $text;
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      top: 50%;
      margin-left: -3px;
      margin-top: -5px;
    }

    &:hover {
      outline: none;
      border-color: $bgDark;
      background: $bgDark;
      color: transparent;

      &:before {
        border-top: 1px solid white;
        border-right: 1px solid white;
      }
    }
    &.slick-disabled {
      opacity: 1;
    }
  }

  .slick-prev {
    left: 50%;
    margin-left: -39px;

    &:before {
      transform: rotate(225deg);
    }

    [dir="rtl"] & {
      left: auto;
      right: 24px;
      transform: rotate(45deg);
    }
  }

  .slick-next {
    right: 50%;
    margin-right: -40px;

    &:before {
      transform: rotate(45deg);
      margin-left: -7px !important;
    }

    [dir="rtl"] & {
      left: 24px;
      right: auto;
      transform: rotate(225deg);
    }
  }
}

.similarItemTile {
  width: span(12);
  float: left;
  padding-right: 0;

  margin-left: 0;
  margin-bottom: lineHeight();

  .itemImg {
    margin-bottom: lineHeight()/2;
  }

  &:hover, &:focus {
    .itemTitle, .itemUsedTitle {
      color: $active;
      transition: all .5s;
    }
    .itemImg img {
      max-width: 110%;
      left: -5%;
      transition: all .5s;
    }
    .itemUsedImg {
      background-color: $bg;

      img {
        opacity: 0;
        transition: all .5s;
      }
    }
  }

  @include breakpoint($xs) {
    padding-right: 1.5rem;
  }
}

//
.carousel-show {
  margin-bottom: lineHeight();
  @extend %clearfix;

  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: lineHeight()*2;

  .slick-prev,
  .slick-next {
    cursor: pointer;
    position: absolute;
    display: block;
    top: auto;
    bottom: -1*lineHeight();
    height: 40px;
    width: 40px;
    background: white;
    color: transparent;
    padding: 0;
    border: 1px solid $line;
    border-radius: 0;
    line-height: 0;
    font-size: 0;
    outline: none;
    z-index: 19;

    transition: all .5s;

    &:before {
      content: '';
      border-top: 1px solid $text;
      border-right: 1px solid $text;
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      top: 50%;
      margin-left: -3px;
      margin-top: -5px;
    }

    &:hover {
      outline: none;
      border-color: $bgDark;
      background: $bgDark;
      color: transparent;

      &:before {
        border-top: 1px solid white;
        border-right: 1px solid white;
      }
    }
    &.slick-disabled {
      opacity: 1;
    }
  }

  .slick-prev {
    left: 50%;
    margin-left: -39px;

    &:before {
      transform: rotate(225deg);
    }

    [dir="rtl"] & {
      left: auto;
      right: 24px;
      transform: rotate(45deg);
    }
  }

  .slick-next {
    right: 50%;
    margin-right: -40px;

    &:before {
      transform: rotate(45deg);
      margin-left: -7px !important;
    }

    [dir="rtl"] & {
      left: 24px;
      right: auto;
      transform: rotate(225deg);
    }
  }

  .galleryItemTile {
    width: 100%;

    margin: 0;
    margin-bottom: lineHeight();

    @include breakpoint($xs) {
      width: 100%;
      margin-left: 0;
      padding-right: 1.5rem;
    }
    @include breakpoint($lg) {
      width: 100%;

      &:nth-child(2n+1) {
        margin-left: 0;
      }
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }
  }
}
// Video
.video {
  float: none;
  position:relative;
  width: 100%;
  margin-bottom: lineHeight();
  padding-bottom: 56.25%;
  height:0;
  overflow:hidden;

  &:nth-child(2n+1) {
    margin-left: 0;
  }

  iframe, object, embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  @include breakpoint($xs) {
    width: 48%;
    float: left;
    padding-bottom: 27%;

    &:nth-child(2n) {
      margin-left: 4%;
    }
  }
}