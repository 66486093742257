﻿// Left side
.leftSide {
  width: 100%;
  float: none;
  padding: lineHeight() 0;

  &+.content {
    margin-left: 0;
    padding-top: 0;
  }

  @include breakpoint($md) {
    width: 240px;
    float: left;

    &+.product, &+.content {
      margin-left: 300px;
      padding-top: lineHeight()/3;
    }
  }
}

.orderLeft {
  display: block;
  width: 100%;
  padding: lineHeight()/2 lineHeight();
  background-color: $bgDarken;
  border-color: $bgDarken;
  color: $bg;
  font-size: typeScale(0);
  font-family: $fontDecor;
  font-weight: bold;
  text-transform: uppercase;

  @include  breakpoint(max-width ($md - 1)) {
    display: none;
  }
}

// Catalog page

.catalog {
  display: flex;
  flex-wrap: wrap;
  margin-top: lineHeight()*3/2;
}

.catalogMenu {
  width: 100%;
  margin-bottom: lineHeight();
  margin-right: 0;

  &:nth-child(2n+1) {
    margin-right: 0;
  }

  @include breakpoint($xs) {
    width: calc(50% - (1.563/2)*1rem);
    margin-right: lineHeight();

    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(2n+1) {
      margin-right: lineHeight();
    }
  }

  @include breakpoint($lg) {
    width: calc(100%/3 - 1.563*2rem);
    margin-right: lineHeight();

    &:nth-child(2n) {
      margin-right: lineHeight();
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.catalogMenuTitle {
  padding: lineHeight()/2;
  color: $bg;
  background-color: rgba(0,0,0, .3);

  svg {
    float: left;
  }
  h5 {
    margin-left: 36px;
  }
}

.subcatalogMenu {
  padding: lineHeight()/2;

  a, a:hover, a:focus, a:visited {
    display: inline-block;
    position: relative;
    padding: lineHeight()/4 lineHeight()/2;
    color: $bg;
    opacity: 1;

    &:before {
      content: '';
      background-color: $bg;
      display: block;
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      top: 16px;
      left: 0;
    }
  }
  a:hover, a:focus {
    text-decoration: none;
    opacity: .6;
  }
}

// Product page
.product {
  overflow: auto;
  position: relative;
  margin-bottom: lineHeight();
}
.productTitle {
  width: 100%;
  float: none;
  text-align: center;

  @include breakpoint($lg) {
    width: 35%;
    float: left;
  }
}
.productImg {
  width: 100%;
  float: none;
  padding: lineHeight();
  border: 1px solid $line;

  @include breakpoint($lg) {
    width: 60%;
    float: right;
  }
}
.productImg img {
  margin: auto;
}
.productThumb {
  display: none;

  @include breakpoint($lg) {
    display: block;
    overflow: auto;
    margin: lineHeight() 0;
  }
}
.thumbnail {
  float: left;
  cursor: pointer;
  overflow: hidden;
  margin: 10px;
  width: 80px;
  height: 80px;

  img {
    display: inline-block;
    border: 1px solid $line;
    padding: 10px;
  }
}
.productPrice {
  @include typeSetting(2);

  color: $active;
  font-weight: bold;

  span {
    font-weight: normal;
    font-size: typeScale(0);
  }
}
.orderProduct {
  width: 160px;
}
.productDescription {
  padding-top: lineHeight();
  border-top: 1px solid $line;

  h1, h2, h3 {
    @include typeSetting(1);
    font-family: $fontBase;
    color: $textDark;
    text-align: left;
    margin-bottom: 0;
    margin-top: lineHeight()/2;
    padding-left: 0;
    font-weight: 500;
    border: none;
  }
}
