﻿// Menu stiles
.nav {
  background-color: $bgDarken;
  background-image: none;
  padding: lineHeight()/2 0;

  @include  breakpoint($md) {
    background-color: $bg;
    background-image: linear-gradient(to top, $bg 0, $bg 10%, $bg 90%, #c9c8cc 100%);
  }
}

.menuTop {
  display: block;
  overflow: hidden;
  width: span(10 wide);
  float: left;
  padding-top: lineHeight()/2;

  @extend %menuLi;

  li {
    padding-right: 1rem;
  }

  a, a:hover, a:focus, a:visited {
    display: inline-block;
    color: $bgDarken;
    text-transform: uppercase;
    font-size: typeScale(-1);
  }

  .active>a, a:hover, a:focus  {
    color: $active;
    text-decoration: none;
  }

  @include  breakpoint(max-width ($md - 1)) {
    display: none;
  }

}
.orderTop {
  background-color: $active;
  color: $bg;
  font-family: $fontDecor;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  float: none;
  width: 270px;
  display: block;
  line-height: 1.25rem;
  padding: lineHeight()/4;
  margin: 0 auto;

  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;

  &:hover, &:focus  {
    background-color: $bgDarken;
    color: $active;
    line-height: 1.25rem;
    transform: perspective(1px) translateZ(0);
    animation-play-state: paused;
  }

  @include breakpoint($md) {
    float: right;
    width: 110px;
  }
}

.lang, .social {
  width: 50%;

  ul {
    @extend %menuLi;
    padding-top: lineHeight()/2;
  }

  a, a:hover, a:focus, a:visited {
    font-size: typeScale(-1);
    color: $textSlogan;
  }

  a, a:visited {
    opacity: .7;
  }

  a:hover, a:focus {
    opacity: 1;
  }
}

.lang {
  float: right;

  ul {
    float: right;
  }

  li:not(:first-of-type) {
    padding-left: 1rem;
  }

  a.active {
    color: $active;
    opacity: 1;
  }

  span {
    display: none;
  }

  @include breakpoint($xs) {
    span {
      display: inline;
    }
  }
}

.social {
  float: left;

  li {
    padding-top: 3px;

    &:not(:last-of-type){
      padding-right: 1rem;
    }
  }

  img {
    height: typeScale(1);
    width: auto;
  }

  @include breakpoint($xs) {

  }
}

.breadcrumbs {
  padding: lineHeight()/2 0;
  //border-bottom: 1px solid rgba($line, .5);

  ul {
    padding-bottom: 0;

    @extend %menuLi;
  }

  li {
    font-size: typeScale(-1);
    color: $textLight;

    &:not(:first-of-type):before {
      content: '/';
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  a, a:hover, a:focus, a:visited {
    font-size: typeScale(-1);
    color: $textLight;
    display: inline-block;
  }
  a:hover, a:focus {
    color: $text;
  }

  @include  breakpoint(max-width ($md - 1)) {
    display: none;
  }
}

.menuFooter, .menuCatalog {
  li {
    position: relative;
    padding-left: 16px;
    line-height: lineHeight();

    &:before {
      content: "";
      position: absolute;
      top: 11px;
      left: 0;
      width: 4px;
      height: 4px;
      background-color: $active;
    }
  }
}

.menuLeft{
  width: 100%;
  border: 1px solid $line;
  border-top: 3px solid $active;

  h3 {
    font-family: $fontDecor;
    color: $textTitle;
    font-weight: bold;
    padding: lineHeight()/2;
    margin-bottom: 0;
    cursor: pointer;

    @include breakpoint($md) {
      cursor: auto;
    }
  }

  ul {
    padding-bottom: 0;
  }

  li {
    border-top: 1px solid $line;
  }

  a, a:hover, a:focus, a:visited {
    display: inline-block;
    width: 100%;
    padding: lineHeight()/2;
    font-size: typeScale(-1);
    color: $textDark;
    background-color: $bg;
  }
  a:hover, a:focus {
    color: $active;
  }

  .active>a  {
    color: $active;
    background-color: $bgLight;
  }
}

.catalogMap {
   a {
     position: relative;

     &:after {
       content: '';
       position: absolute;
       width: 0;
       height: 0;
       right: 10px;
       top: 20px;
       border: 4px solid;
       color: transparent;
       border-left-color: $textDark;
     }
   }
  a:hover:after, a:focus:after {
    border-left-color: $active;
  }
  .active a:after {
    right: 12px;
    border-top-color: $active;
    border-left-color: transparent;
  }

  ul {
    display: none;

    li {
      border-top: none;
    }

    a, a:visited {
      padding-top: 0;
      padding-left: lineHeight();
      opacity: .7;

      &:after {
        content: none;
      }
    }
    a:hover, a:focus {
      padding-top: 0;
      opacity: 1;
      color: $textTitle;
    }
    .active a, .active a:hover, .active a:focus, .active a:visited {
      opacity: 1;
      color: $textTitle;
      padding-left: lineHeight();
    }
  }

  .active a {
    background-color: $bg;
  }

  .active ul {
    display: block;
  }
}

.menuPortfolio {
  display: none;

  @include breakpoint($md) {
    display: block;
  }
}



.menuBottom {

  a, a:hover, a:focus, a:visited {

  }


}

// Pagination
div.pagination {
  @extend %menuLi;
  text-align: center;
  border-top: 1px solid $line;
}
ul.pagination {
  overflow: auto;
  display: inline-block;
  width: auto;
  margin: lineHeight() auto;
  padding-bottom: 0;

  li {
    padding: 0 5px;
    font-size: 1rem;

    &:before {
      content: none;
    }
  }

  a, a:visited, span {
    display: table-cell;
    height: 30px;
    width: 30px;
    text-align: center;
    vertical-align: middle;
    background-color: $bg;
    border: 1px solid $line;
    color: $textLight;
  }
  a:hover, a:focus {
    text-decoration: none;
    color: $active;
    transition: all .5s;
    text-decoration: none;
  }
  .active > a {
    text-decoration: none;
    color: $active;
    background-color: $bgLight;
    transition: all .5s;
    text-decoration: none;
  }

  .disabled, .control {
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    color: transparent;
  }
  .control:hover, .control:focus, .disabled {
    opacity: .5;
  }
  li:nth-of-type(1) {
    a, span {
      background: url("../img/first.png") left top no-repeat;
      border: none;
    }
  }
  li:nth-of-type(2) {
    a, span {
      background: url("../img/prev.png") left top no-repeat;
      border: none;
    }
  }
  li:nth-last-of-type(1) {
    a, span {
      background: url("../img/last.png") left top no-repeat;
      border: none;
    }
  }
  li:nth-last-of-type(2) {
    a, span {
      background: url("../img/next.png") left top no-repeat;
      border: none;
    }
  }
}


// Mobile menu
label#mobile {
  display: block;

  .menuMobIcon {
    position: fixed;
    left: 5px;
    top: 5px;
    z-index: 100;
    width: 40px;
    height: 40px;
    //background: rgba(255,255,255,.8);
    //border-radius: 50% 50% 50% 50%;
    transition: .5s ease-in-out;
    box-shadow: 0 0 0 0 #FFF, 0 0 0 0 #FFF;
    cursor: pointer;
  }
  .hamburger {
    position: absolute;
    top: 19px;
    left: 8px;
    width: 24px;
    height: 2px;
    background: rgba($bg, .8);
    display: block;
    transform-origin: center;
    transition: .5s ease-in-out;
    &:after,
    &:before {
      transition: .5s ease-in-out;
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba($bg, .8);
    }
    &:before {
      top: -6px;
    }
    &:after {
      bottom: -6px;
    }
  }
  input {
    display: none;
  }
  input:checked + .menuMobIcon {
    background: rgba(255,255,255,1);
    box-shadow: 0 0 0 100vw #FFF, 0 0 0 100vh #FFF;
    border-radius: 0;
    .hamburger {
      transform: rotate(45deg);
      background-color: $textDark;
      &:after {
        background-color: $textDark;
        transform: rotate(90deg);
        bottom: 0;
      }
      &:before {
        background-color: $textDark;
        transform: rotate(90deg);
        top: 0;
      }
    }
  }

  input:checked + .menuMobIcon + .menuMob {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: .25s .2s ease-in-out;
  }

  .menuMob {
    z-index: 200;
    position: fixed;
    top: -100%;
    left: -100%;
    transform: translate(0,0);
    opacity: 0;
    transition: .25s 0s ease-in-out;

    a, a:hover, a:focus, a:visited {
      color: $active;
      font-size: typeScale(1);
      line-height: lineHeight()*1.5;
    }
  }

  @include breakpoint($md - 1) {
    display: none;
  }
}
