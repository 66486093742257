﻿@keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes pulse-in {
  to {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

@keyframes pulse {
  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  75% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

@keyframes buzz-out {
  10% {
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    transform: translateX(1px) rotate(0);
  }
  100% {
    transform: translateX(-1px) rotate(0);
  }
}