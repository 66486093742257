//Susy
$susy: (
  'columns': susy-repeat(12)
);

// Colors
  $bg: #ffffff;
  $bgLight: #f2f3f4;
  $bgDark: #513369;
  $bgDarken: #3b1758;
  $line: #cacaca;
  $text: #777777;
  $textLight: #c0c0c0;
  $textDark: #777777;
  $textTitle: #000000;
  $textSlogan: #cccef7;
  $active: #ef4482;
  $colorSound: #fc5b8d;
  $colorLight: #fcd424;
  $colorScene: #a36acb;
  $colorMedia: #769ba3;
  $colorDJ: #c759af;
  $colorArtists: #5361ad;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic');

@font-face {
  font-family: 'Bauhaus';
  src: url('../fonts/BauhausLight/BauhausLightC.eot');
  src: url('../fonts/BauhausLight/BauhausLightC.woff2') format('woff2'),
  url('../fonts/BauhausLight/BauhausLightC.woff') format('woff'),
  url('../fonts/BauhausLight/BauhausLightC.ttf') format('truetype'),
  url('../fonts/BauhausLight/BauhausLightC.svg#BauhausLightC') format('svg'),
  url('../fonts/BauhausLight/BauhausLightC.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bauhaus';
  src: url('../fonts/BauhausMedium/BauhausMediumC.eot');
  src: url('../fonts/BauhausMedium/BauhausMediumC.woff2') format('woff2'),
  url('../fonts/BauhausMedium/BauhausMediumC.woff') format('woff'),
  url('../fonts/BauhausMedium/BauhausMediumC.ttf') format('truetype'),
  url('../fonts/BauhausMedium/BauhausMediumC.svg#BauhausMediumC') format('svg'),
  url('../fonts/BauhausMedium/BauhausMediumC.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}

$fontBase: 'Roboto', sans-serif;
$fontDecor: 'Bauhaus', sans-serif;

$base-font-size: 1;
$base-line-height: 1.563;

$type-scale: (
        -2: $base-font-size * 0.78rem,  // extra small text
        -1: $base-font-size * 0.875rem,  // small text
        0: $base-font-size * 1rem,  // body text
        1: $base-font-size * 1.25rem,  // large text (h3)
        2: $base-font-size * 1.563rem,  // h2
        3: $base-font-size * 1.953rem   // h1
);
$line-height: (
        -2: $base-line-height * 1rem,
        -1: $base-line-height * 1rem,
        0: $base-line-height * 1rem,
        1: $base-line-height * 1rem ,
        2: $base-line-height * 1rem,
        3: $base-line-height * 1.5rem
);

@function lineHeight($level: 0) {
  @return map-get($line-height, $level);
}
@function typeScale($level: 0) {
  @return map-get($type-scale, $level);
}

// Breakpoints

$xxs: 360px;
$xs: 480px;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1366px;

// Libraries

%clearfix {
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }

  &:after {
    clear: both;
  }
}
%menuLi {
  li{
    float: left;
    text-align: center;

    a:hover, a:focus {
      text-decoration: none;
    }
  }
}
%icon {
  background: url("../img/icons.png") no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  border: none;
  padding: 0;
}



