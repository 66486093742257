// Typography

h1, h2 {
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: lineHeight();
}

h1 {
  @include typeSetting(2);
  font-family: $fontDecor;
  border-left: 4px solid $active;
  padding-left: 12px;
  padding-bottom: 0;
}

h2 {
  @include typeSetting(1);
  font-family: $fontDecor;
  border-left: 4px solid $active;
  padding-left: 12px;
  padding-bottom: 0;

  @include breakpoint($lg) {
    @include typeSetting(2);
  }
}

h3 {
  @include typeSetting(1);
  font-family: $fontBase;
  color: $textTitle;
  text-align: left;
  margin-bottom: lineHeight();
}

a, a:hover, a:focus, a:visited {
  @include typeSetting(-1);

  text-decoration: none;
  transition: all .5s;

  @include breakpoint($md) {
    @include typeSetting(0);
  }
}
a:hover, a:focus {
  text-decoration: none;
  transition: all .5s;
}
b, strong {
  font-weight: bold;
}
i, em {
  font-style: italic;
}
p, ul, ol, pre, table, blockquote {
  @include typeSetting(-1);

  font-family: $fontBase;
  color: $text;
  margin-top: 0;
  padding-bottom: lineHeight()/2;
  text-align: left;

  @include breakpoint($md) {
    @include typeSetting(0);
  }
}
ul ul, ol ol, ul ol, ol ul {
  text-align: left;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

hr, .hr {
  background-color: $line;
  border: 0;
  height: 1px;
}
sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}


