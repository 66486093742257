﻿@include placeholder {
  color: $textLight;
  opacity: 1;
}

button {
  cursor: pointer;
  transition: all .3s;
}

button, input, textarea, select {
  outline: none;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

button, input, textarea {
  display: inline-block;
  margin-bottom: lineHeight();
  text-align: center;
  transition: all .3s;
}

input[type="text"], textarea {
  padding: lineHeight(0)/2 gutter();

  &:focus {

    transition: all .3s;
  }
}

label {

}

.button, input[type="submit"] {
  display: inline-block;
  padding: lineHeight()/4 lineHeight()/2;
  text-align: center;
  text-decoration: none;
  border: 1px solid $line;
  color: $textLight;
  background-color: $bgLight;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition: all .3s;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $active;
    border-color: $active;
    transform: scale(0);
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-out;
  }

  &:hover, &:focus {
    text-decoration: none;
    color: $bg !important;

    &:before {
      transform: scale(1);
    }
  }
}

input[type="submit"] {
  &:hover, &:focus {
    text-decoration: none;
    color: $bg !important;
    background-color: $active;
    border-color: $active;
  }
}


// Forms
.formFeedback {
  width: 100%;
  background-color: $bg;
  padding: lineHeight() 0;

  textarea {
    font-size: typeScale(1);
  }

  input[type=submit] {
    cursor: pointer;
  }

  label {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    font-size: typeScale(1);
    font-weight: 300;
    margin: lineHeight()/3;
    padding: 0;
    background-color: $bg;
    transition: all .3s ease-in-out;
  }

  div {
    position: relative;
    height: 70px;

    &:last-of-type {
      height: 205px;
    }
  }

  .active {
    color: $textLight;
    top: -20px;
    font-size: typeScale(0);
    padding: 0 lineHeight()/2;
    transition: all .3s ease-in-out;
  }
}

.formControl {
  width: 100%;
  position: absolute;
  top: 0;
  padding: lineHeight();
  border: 1px solid $line;
  font-size: typeScale(0);
  text-align: left;
  background-color: $bg;
  color: $text;

  &:focus+label {
    color: $textLight;
    top: -20px;
    font-size: typeScale(0);
    padding: 0 lineHeight()/2;
    transition: all .3s ease-in-out;
  }
}

.formControl:focus {
  outline: none;
}