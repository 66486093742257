﻿// Index page styles
//About, clients
.indexAbout, .indexClient {
  width: span(12);
  float: none;
  padding-right: 0;
  padding-bottom: lineHeight();
  text-align: center;

  p:last-of-type {
    padding-bottom: lineHeight();
  }
}

@include breakpoint($lg) {
  .indexAbout {
    width: span(6);
    float: left;

    padding-right: lineHeight();
  }
  .indexClient {
    width: span(6);
    float: right;
  }
}

//Catalog links
.indexCatalog {
  width: 100% - gutter()*2;
  margin: 0 gutter();
  padding: lineHeight() 0 lineHeight()/2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include breakpoint($lg) {
    flex-wrap: nowrap;
  }

  @include breakpoint($xl) {
    max-width: $xl;
    margin: 0 auto;
  }
}
.indexCategory {
  width: 100%;
  margin-bottom: lineHeight()/2;
  padding: lineHeight();
  transform: perspective(1px) translateZ(0);
  transition: .5s;

  &:hover, &:focus, &:active {
    animation-name: pulse-in;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @include breakpoint($md) {
    width: span(4);
  }

  @include breakpoint($lg) {
    width: span(2);
  }
}
.categorySound {
  background-color: $colorSound;
}
.categoryLight {
  background-color: $colorLight;
}
.categoryScene {
  background-color: $colorScene;
}
.categoryMedia {
  background-color: $colorMedia;
}
.categoryDj {
  background-color: $colorDJ;
}
.categoryArtists {
  background-color: $colorArtists;
}
.categoryTitle {
  font-size: typeScale(-1);
  line-height: 1rem;
  color: $bg;
  text-align: center;
}
.categoryImg {
  width: 5rem;
  height: 5rem;
  margin: auto;
  text-align: center;
  color: $bg;
}

//Portfolio
.indexPortfolio {
  display: flex;
  flex-wrap: wrap;
  padding: lineHeight() 0;

  .buttonPortfolio>.button {
      padding: lineHeight()/3 lineHeight()/2;
      background-color: $active;
      font-family: $fontBase;
      font-weight: normal;
      text-transform: none;

      &:before {
          background-color: $bgDark;
      }
  }
}

.indexPortfolioItem {
  max-width: 480px;
  width: span(12);
  padding-bottom: lineHeight();
  margin: auto;

  @include breakpoint($sm) {
    max-width: 100%;
  }
  @include  breakpoint($md) {
    width: span(10);
  }
  @include  breakpoint($lg) {
    width: span(6);
  }
}
.indexPortfolioWrapper {
  width: 100%;
  text-align: center;

  @include  breakpoint($sm) {
    text-align: left;
  }
}
.indexPortfolioImg {
  width: 100%;
  height: auto;
  float: none;
  overflow: auto;
  background-color: $bgLight;
  position: relative;

  img {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all .5s;
  }

  @include  breakpoint($sm) {
    width: 13rem;
    height: 10rem;
    float: left;
    overflow: hidden;

    img {
      position: absolute;
      height: 100%;
      max-width: inherit;
    }
  }
}
.indexPortfolioText {
  margin-left: 0;
  padding-right: 0;
  height: auto;
  font-size: typeScale(1);
  overflow: auto;

  &+.button {
    margin-left: auto;
    margin-top: 0;
  }

  @include  breakpoint($sm) {
    font-size: typeScale(0);
    margin-left: 14rem;
    padding-right: 1rem;
    margin-bottom: lineHeight()/2;
    height: lineHeight()*4;
    overflow: hidden;

    &+.button {
      margin-left: 1rem;
    }
  }
  @include  breakpoint($lg) {
    font-size: typeScale(1);
  }
}
.seoText {
  font-weight: 500;
  cursor: pointer;
  color: $textTitle;

  &:hover {
    color: $active;
  }
}