// Container
@mixin container($width: 100%, $justify: auto auto, $property: max-width) {
  max-width: $width;
  margin-left: nth($justify, 1);
  margin-right: nth($justify, 2);
}

// Type settings
@mixin typeSetting($level: 0) {
  font-size: typeScale($level);
  line-height: lineHeight($level);
}
//Placeholder
@mixin placeholder {
  &::-webkit-input-placeholder { @content; }
  &:-moz-placeholder           { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }
}
// Gallery
@mixin gallery($columns) {
  $gutter: 2%;
  $columnWidth: 100% / $columns - $gutter;
  $gutterWidth: $gutter*$columns / ($columns - 1);

  box-sizing: border-box;
  width: $columnWidth;
  float: left;

  @for $item from 1 through $columns {
    $nth: '#{$columns}n + #{$item}';
    &:nth-child(#{$nth}) {
      margin-left: ($gutterWidth + $columnWidth)*$item - ($gutterWidth + $columnWidth);
      margin-right: -100%;

      @if $item == 1 {
        clear: both;
      }@else{
        clear: none;
      }
    }
  }
}

// Layout
@mixin layout($width: 50%, $layout: left) {
  width: $width;
  float: $layout;
}

// Baseline grid
@mixin baseline-grid(){

  $baseline-color: rgba(56,255,255,.8);
  $baseline: $base-line-height * 16 * 1px;

  background-image: repeating-linear-gradient(transparent 0px, transparent ($baseline - 1), $baseline-color $baseline, $baseline-color $baseline);
}

