﻿// Contact page
.contactInfo {
  width: 100%;
  float: none;
  padding-right: 0;
  padding-top: lineHeight();

  @include breakpoint($md) {
    width: 50%;
    float: left;
    padding-right: lineHeight();
  }
}

.contactForm {
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  padding-top: .5rem;

  @include breakpoint($md) {
    width: 50%;
    margin-left: 50%;
    padding-left: lineHeight();
  }
}
.contactItem {
  position: relative;
  padding-top: 1px;
  margin-bottom: lineHeight();

  p {
    margin-left: 30px;
    padding-bottom: 0;
  }

  a, a:hover, a:focus, a:visited {
    display: inline-block;
    width: 100%;
  }

  a, a:focus, a:visited {
    color: $text;
  }
}

.contactImg {
  width: 100%;
  height: 10*lineHeight();
  background: url("/uploads/office.jpg") no-repeat bottom center;
  background-size: cover;
}

#map {
  width: 100%;
  height: 500px;
}