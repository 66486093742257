﻿// About page
.buttonPortfolio {
  margin: lineHeight() auto;
  text-align: center;

  .button {
    border: none;
  }

  a, a:hover, a:focus, a:visited {
    background-color: $bgDark;
    padding: lineHeight()/3 lineHeight()/2 lineHeight()/5;
    color: $bg;
    font-family: $fontDecor;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
  }
  a:hover, a:focus {
    background-color: $active;
  }
}